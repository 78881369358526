import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { closeGlobalSnackbar } from '../../actions/global_snackbar';

const SnackbarMessage = props => (
  <div>
    <Snackbar
      open={props.global_snackbar.open}
      message={props.global_snackbar.message}
      onClose={props.closeGlobalSnackbar}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  </div>
);

SnackbarMessage.propTypes = {
  closeGlobalSnackbar: PropTypes.func.isRequired,
  global_snackbar: PropTypes.object,
};

SnackbarMessage.defaultProps = {
  global_snackbar: { open: false, message: '' },
};

function mapStateToProps(state) {
  // eslint-disable-next-line camelcase
  const { global_snackbar } = state;
  return {
    global_snackbar,
  };
}

export default connect(mapStateToProps, { closeGlobalSnackbar })(
  SnackbarMessage,
);
