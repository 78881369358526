/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextField from 'material-ui/TextField/TextField';
import FlatButton from './FlatButtonSingleClick';
import GlobalDialog from './Dialog';
import GlobalTitle from './Title';
import Close from '../../../images/global/Close.svg';

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancellationReason: '',
    };
  }

  handleChange = event => {
    this.setState({ cancellationReason: event.target.value });
  };

  renderCancellationActions = () => {
    const { intl } = this.props;
    return (
      <div className="ccd-action" style={{ textAlign: 'center' }}>
        <FlatButton
          label={intl.formatMessage({ defaultMessage: 'Cancel Appointment' })}
          className="big-flat-button-red"
          onClick={() => {
            this.props.handleClose();
            this.props.handleConfirm(this.state.cancellationReason);
            this.setState({ cancellationReason: '' });
          }}
        />
      </div>
    );
  };

  render() {
    const {
      open,
      handleClose,
      message,
      handleConfirm,
      forCancellation,
      intl,
    } = this.props;

    return (
      <GlobalDialog
        modal={false}
        open={open}
        autoDetectWindowHeight={false}
        contentClassName="confirmation-dialog"
        handleClose={handleClose}
      >
        <GlobalTitle
          title={
            forCancellation
              ? intl.formatMessage({
                  defaultMessage: 'Confirm Appointment Cancellation',
                })
              : intl.formatMessage({ defaultMessage: 'Confirmation' })
          }
        />
        <div className="ccd-content">
          {forCancellation ? (
            <div>
              <p>
                {intl.formatMessage({
                  defaultMessage:
                    'Would you like to leave a cancellation reason?',
                })}
              </p>
              <TextField
                name="cancellation_reason"
                multiLine
                rows={2}
                rowsMax={4}
                onChange={this.handleChange}
                required
                placeholder={intl.formatMessage({
                  defaultMessage: 'Reason (optional)',
                })}
              />
            </div>
          ) : (
            message
              .split('\n')
              .map((item, index) => <p key={`k-${index + 1}`}>{item}</p>)
          )}
        </div>
        {forCancellation ? (
          this.renderCancellationActions()
        ) : (
          <div className="ccd-action">
            <FlatButton
              label={intl.formatMessage({ defaultMessage: 'No' })}
              className="big-flat-button-red"
              onClick={handleClose}
              style={{ marginRight: '20px' }}
            />
            <FlatButton
              label={intl.formatMessage({ defaultMessage: 'Yes' })}
              className="big-flat-button-green"
              onClick={() => {
                handleClose();
                handleConfirm(this.state.cancellationReason);
                this.setState({ cancellationReason: '' });
              }}
            />
          </div>
        )}
        <Close onClick={handleClose} className="ccd-close-button" />
      </GlobalDialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  forCancellation: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

ConfirmationDialog.defaultProps = {
  forCancellation: false,
};

export default injectIntl(ConfirmationDialog);
