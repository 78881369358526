import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../images/global/spinner.svg';

const ModuleLoadingSpinner = () => (
  <div className="module-loading-spinner">
    <div className="mls-spinner">
      <SvgIcon color="#2855c3">
        <Spinner />
      </SvgIcon>
    </div>
    <div className="mls-message">
      <FormattedMessage defaultMessage="Loading ..." />
    </div>
  </div>
);

export default ModuleLoadingSpinner;
