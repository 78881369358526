import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import FileFileDownload from 'material-ui/svg-icons/navigation/expand-more';
import { timezones, getTimezoneLabel } from '../../utils/timezone';
import { updatePreferences } from '../../actions/preferences';
import { injectIntl } from 'react-intl';

class TimezoneSelect extends Component {
  constructor() {
    super();
    this.state = {
      time: null,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.update, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  update = () => {
    const { user } = this.props;
    const timezone = user && user.preference && user.preference.timezone;
    const currentTime = timezone ? moment().tz(timezone) : moment();

    this.setState({
      time: currentTime,
    });
  };

  openSelect = () => this.setState({ isOpenSelect: true });

  closeSelect = () => this.setState({ isOpenSelect: false });

  handleChange = (event, value) => {
    const { user } = this.props;
    const timezone = user && user.preference && user.preference.timezone;
    const preferenceValues = { preference: { timezone: value } };

    if (timezone && timezone !== value) {
      this.props.updatePreferences(preferenceValues, () => {
        window.location.reload(false);
      });
    }
  };

  render() {
    const { time } = this.state;
    const { user, intl } = this.props;
    const timezone = user && user.preference && user.preference.timezone;

    const formattedTime = intl.formatDate(time, {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: localStorage.getItem('current-timezone'),
    });

    return (
      <div className="timezone-select">
        <div className="tz-time">
          <div className="tz-timezone">
            {timezone && getTimezoneLabel(timezone)}
          </div>
          <div className="tz-current">
            {intl.formatMessage({ defaultMessage: 'Time Now' })} :{' '}
            {formattedTime}
          </div>
        </div>
        <div className="tz-dropdown">
          <IconMenu
            iconButtonElement={
              <IconButton>
                <FileFileDownload color="#f9f9f9" />
              </IconButton>
            }
            onChange={this.handleChange}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {timezones.map(zone => (
              <MenuItem
                value={zone.value}
                primaryText={zone.label}
                key={zone.value}
              />
            ))}
          </IconMenu>
        </div>
      </div>
    );
  }
}

TimezoneSelect.propTypes = {
  user: PropTypes.object.isRequired,
  updatePreferences: PropTypes.func.isRequired,
};

export default injectIntl(
  connect(null, {
    updatePreferences,
  })(TimezoneSelect),
);
