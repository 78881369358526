import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';

class FlatButtonSingleClick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }

  render() {
    return (
      <FlatButton
        {...this.props}
        onClick={() => {
          this.setState({ disabled: true });
          this.props.onClick();
        }}
        disabled={this.state.disabled}
      />
    );
  }
}

export default FlatButtonSingleClick;
