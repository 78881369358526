import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import glowLoadingData from '../../../images/lottie/glow_loading.json';

const LoadingPanel = props => {
  const { message } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: glowLoadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="global-loading-panel">
      <div className="panel-wrapper">
        <Lottie
          options={defaultOptions}
          width={200}
          height={200}
          isClickToPauseDisabled
        />
        <div className="panel-message">
          {message || <FormattedMessage defaultMessage="Please Wait" />}
        </div>
      </div>
    </div>
  );
};

LoadingPanel.propTypes = {
  message: PropTypes.string,
};

export default LoadingPanel;
