import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const ONE_HUNDRED_MB = 104857600;

const DropzoneInput = props => (
  <Dropzone
    name={props.name}
    multiple={props.multiple}
    accept={props.accept}
    onDrop={filesToUpload => {
      props.onChange(filesToUpload);
    }}
    style={{}}
    maxSize={props.maxSize}
    onDropRejected={props.onDropRejected}
    onDropAccepted={props.onDropAccepted}
  >
    <div>{props.component}</div>
  </Dropzone>
);

DropzoneInput.propTypes = {
  accept: PropTypes.string.isRequired,
  component: PropTypes.node,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  maxSize: PropTypes.number,
  onDropRejected: PropTypes.func,
  onDropAccepted: PropTypes.func,
};

DropzoneInput.defaultProps = {
  component: null,
  multiple: false,
  name: '',
  onChange: () => {},
  maxSize: ONE_HUNDRED_MB,
  onDropRejected: () => {},
  onDropAccepted: () => {},
};

export default DropzoneInput;
