import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MuiDialog from 'material-ui/Dialog';
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import { store, inkThemeV0 } from '../../app';

class Dialog extends Component {
  componentDidMount() {
    this.modalTarget = document.createElement('div');
    this.modalTarget.className = 'modal';
    document.body.appendChild(this.modalTarget);
    this.renderDialog();
  }

  componentDidUpdate() {
    this.renderDialog();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.modalTarget);
    document.body.removeChild(this.modalTarget);
  }

  renderDialog() {
    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider muiTheme={inkThemeV0}>
          <div>
            <MuiDialog
              title={this.props.title}
              actions={this.props.actions}
              modal={false}
              open={this.props.open}
              contentClassName={this.props.contentClassName}
              bodyClassName={this.props.bodyClassName}
              onRequestClose={this.props.handleClose}
              contentStyle={this.props.contentStyle}
              titleStyle={this.props.titleStyle}
            >
              {this.props.children}
            </MuiDialog>
          </div>
        </MuiThemeProvider>
      </Provider>,
      this.modalTarget,
    );
  }

  render() {
    return <noscript />;
  }
}

Dialog.propTypes = {
  title: PropTypes.node,
  actions: PropTypes.node,
  open: PropTypes.bool,
  contentClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  contentStyle: PropTypes.object,
  titleStyle: PropTypes.object,
};

Dialog.defaultProps = {
  title: null,
  actions: null,
  contentClassName: null,
  bodyClassName: null,
  open: false,
  contentStyle: {},
  titleStyle: {},
};

export default Dialog;
