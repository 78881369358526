/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ROOT_URL } from '../../environment';
import isAdmin from '../../utils/isAdmin';
import TimezoneSelect from './TimezoneSelect';
import UserInfo from './UserInfo';
import UserInfoV1 from './UserInfoV1';
import BurgerMenu from './BurgerMenu';

class Toolbar extends Component {
  componentDidMount() {
    if (this.props.authenticated && this.props.checkNewMessage) {
      this.props.checkNewMessage();
    }
  }

  InkLogo = () => (
    <img
      src={`${ROOT_URL}/images/dashboard-navigation/logomarkpractice.png`}
      alt="inkblot"
    />
  );

  determineURL = () => {
    if (isAdmin(this.props.user.roles)) {
      return '/admin';
    }
    return '/dashboard';
  };

  render() {
    const { user, newMessage, flags } = this.props;

    if (flags.practiceFrenchLocalization) {
      return (
        <div className="toolbar">
          <div className="icon-wrapper">
            <Link to={this.determineURL()}>
              <this.InkLogo />
            </Link>
          </div>
          <div className="right-wrapper">
            <TimezoneSelect user={user} />
            <UserInfoV1 {...this.props} user={user} roles={user.roles} />
          </div>
        </div>
      );
    }

    return (
      <div className="toolbar">
        <div className="icon-wrapper">
          <Link to={this.determineURL()}>
            <this.InkLogo />
          </Link>
        </div>
        <div className="right-wrapper">
          <TimezoneSelect user={user} />
          <div className="message-link-container">
            <Link to="/messages">
              <img
                id="messages-icon"
                src="../../../images/global/envelope_icon.svg"
                alt="messages"
              />
              <span className={newMessage ? 'new-message-badge' : 'hidden'}>
                !
              </span>
            </Link>
          </div>
          <UserInfo {...this.props} user={user} roles={user.roles} />
        </div>
        <BurgerMenu />
      </div>
    );
  }
}

Toolbar.propTypes = {
  user: PropTypes.object,
  authenticated: PropTypes.bool,
  checkNewMessage: PropTypes.func,
  newMessage: PropTypes.bool,
  flags: PropTypes.object,
};

export default withLDConsumer()(Toolbar);
