import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { LanguageContext } from '../../LanguageProvider';

const localeItems = [
  {
    value: 'en',
    label: 'English (EN)',
  },
  {
    value: 'fr',
    label: 'Français (FR)',
  },
];

const useStyles = makeStyles({
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
    fontSize: '14px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});

export default function LocaleSelect() {
  const classes = useStyles();
  return (
    <LanguageContext.Consumer>
      {({ locale, setLocale }) => (
        <Select
          disableUnderline
          value={locale}
          inputProps={{
            classes: {
              icon: classes.icon,
              root: classes.root,
            },
          }}
          renderValue={value => value.toUpperCase()}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {localeItems.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => setLocale(item.value)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </LanguageContext.Consumer>
  );
}
