import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { updateAssessmentScore } from '../../actions/sessions';

class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      id: this.props.assessment_id,
    };
  }

  onChange = v => {
    this.setState({ value: v }, () => {
      const { id, value } = this.state;
      this.props.updateAssessmentScore({
        scores: { [id]: { value } },
      });
    });
  };

  render() {
    const { color, maxValue, minValue } = this.props;
    return (
      <div className={`input-range-${color}`}>
        <InputRange
          maxValue={maxValue}
          minValue={minValue}
          value={this.state.value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

RangeSlider.propTypes = {
  color: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  assessment_id: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  updateAssessmentScore: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps, {
  updateAssessmentScore,
})(RangeSlider);
