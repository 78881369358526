import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const renderTabClass = (tab, activeTab) => {
  if (tab.key === activeTab) {
    return 'active-tab';
  } else if (tab.disabled) {
    return 'disabled-tab';
  }
  return 'false';
};

const renderTabs = (tabs, activeTab, userId) =>
  _.map(tabs, tab => (
    <li key={tab.key} className={renderTabClass(tab, activeTab)}>
      {userId ? (
        <Link to={`${tab.url.replace(':id', userId)}`}>{tab.heading}</Link>
      ) : (
        <Link to={`${tab.url}`}>{tab.heading}</Link>
      )}
    </li>
  ));

const Tabs = ({ activeTab, userId, tabs }) => (
  <div className="global-tabs">
    <ul>{renderTabs(tabs, activeTab, userId)}</ul>
  </div>
);

Tabs.propTypes = {
  activeTab: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.array.isRequired,
};

Tabs.defaultProps = {
  activeTab: '',
  userId: null,
};

export default Tabs;
