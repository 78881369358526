import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import _ from 'lodash';
import SignOut from '../auth/SignOut';

const dropdownStyle = {
  padding: 0,
  marginTop: -20,
  fontSize: 12,
  height: 15,
  fontFamily: 'Source Sans Pro, sans-serif',
  display: 'inline-flex',
};

const menuLabelStyle = {
  fontWeight: 600,
  letterSpacing: 0.2,
  paddingLeft: 0,
  color: '#f9f9f9',
};

const userSection = {
  display: 'flex',
  flexDirection: 'row',
};

const userDisplay = {
  justifyContent: 'flex-end',
};
class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutOpen: false,
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({ logoutOpen: !prevState.logoutOpen }));
  };

  render() {
    const { user } = this.props;
    if (!_.isEmpty(user)) {
      return (
        <div className="toolbar-user-info" style={userSection}>
          {user.avatar && user.avatar.url ? (
            <Avatar
              onClick={() =>
                this.props.history.push('/preferences/user_details')
              }
              src={`${user.avatar.url}`}
              size={30}
              style={{
                marginRight: 10,
                alignSelf: 'center',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Avatar
              onClick={() =>
                this.props.history.push('/preferences/user_details')
              }
              color="#adc6eb"
              backgroundColor="#fff"
              size={30}
              style={{
                marginRight: 10,
                cursor: 'pointer',
                alignSelf: 'center',
              }}
            >
              {user.first_name.charAt(0)}
              {user.last_name.charAt(0)}
            </Avatar>
          )}
          <div style={userDisplay}>
            <div style={{ color: '#f9f9f9' }} className="user-info">
              {user.full_name}
            </div>
            <div className="user-change-menu" style={{ height: 27 }}>
              <DropDownMenu
                value={1}
                style={dropdownStyle}
                labelStyle={menuLabelStyle}
                underlineStyle={{ display: 'none' }}
              >
                <MenuItem
                  value={1}
                  onClick={() =>
                    this.props.history.push('/preferences/user_details')
                  }
                  primaryText="Settings"
                />
                <MenuItem
                  value={3}
                  primaryText="Logout"
                  onClick={() => {
                    this.toggleModal();
                  }}
                />
              </DropDownMenu>
            </div>
          </div>
          <SignOut
            {...this.props}
            open={this.state.logoutOpen}
            toggleModal={this.toggleModal}
          />
        </div>
      );
    }
    return <div />;
  }
}

UserInfo.propTypes = {
  user: PropTypes.object,
  roles: PropTypes.array,
  history: PropTypes.object,
};

UserInfo.defaultProps = {
  user: {},
  roles: ['Loading...'],
  history: {},
};

export default UserInfo;
