import React from 'react';

const Title = props => (
  <div className="page-header">
    <p style={{ ...props.style, textAlign: 'left' }} className="title">
      {props.title}
    </p>
    <span className="line" />
    {props.children}
  </div>
);

export default Title;
