import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const colorCode = type => {
  switch (type.toLowerCase()) {
    case 'individual':
      return '#2e5fca';
    case 'couples':
      return '#1fb18c';
    case 'generic':
      return '#19336a';
    default:
      return '#777777';
  }
};

const useStyles = makeStyles({
  root: {
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: '0.1px',
    backgroundColor: props => colorCode(props.type),
    padding: '4px 8px',
    borderRadius: 15,
    color: '#ffffff',
    textTransform: 'capitalize',
    verticalAlign: 'text-top',
  },
});

function ProviderTypeChip(props) {
  const { type, intl } = props;
  const classes = useStyles(props);

  let chipTitle;

  switch (type.toLowerCase()) {
    case 'individual':
      chipTitle = intl.formatMessage({ defaultMessage: 'Individual' });
      break;
    case 'couples':
      chipTitle = intl.formatMessage({ defaultMessage: 'Couples' });
      break;
    case 'generic':
      chipTitle = intl.formatMessage({ defaultMessage: 'Specialized' });
      break;
    default:
      chipTitle = type;
  }

  return <span className={classes.root}>{chipTitle}</span>;
}

ProviderTypeChip.propTypes = {
  type: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ProviderTypeChip);
