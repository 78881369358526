import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const LoadingDots = ({ message, style }) => (
  <div style={style} className="loading-overlay">
    <div className="loading-content">
      <h2>
        {message || <FormattedMessage defaultMessage="Please Wait" />}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </h2>
    </div>
  </div>
);

LoadingDots.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
};
LoadingDots.defaultProps = {
  message: '',
  style: {},
};

export default LoadingDots;
