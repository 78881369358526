import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import SignOut from '../auth/SignOut';

const menuItemsForProvider = [
  { url: '/', title: 'Dashboard' },
  { url: '/schedule', title: 'Schedule' },
  { url: '/session_notes/documents', title: 'Session Notes' },
  { url: '/billing', title: 'Billing' },
  { url: '/payouts', title: 'Payouts' },
  { url: '/preferences/user_details', title: 'Settings' },
  { url: '/support/contact_us', title: 'Support' },
];

const menuItemsForAdmin = [
  { url: '/admin', title: 'Dashboard' },
  { url: '/admin/counsellors', title: 'Counsellors' },
  { url: '/admin/clients', title: 'Clients' },
  { url: '/admin/appointments', title: 'Appointments' },
  { url: '/admin/reports', title: 'Reports' },
  { url: '/admin/companies', title: 'Companies' },
  { url: '/admin/discount_codes', title: 'Discount Codes' },
  { url: '/admin/provider_payouts', title: 'Payouts' },
  { url: '/admin/failed_payments', title: 'Failed Payments' },
];

class BurgerMenu extends Component {
  constructor() {
    super();
    this.state = {
      logoutOpen: false,
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({ logoutOpen: !prevState.logoutOpen }));
  };

  renderNavItem = navItem => (
    <MenuItem
      key={navItem.title}
      primaryText={navItem.title}
      onClick={() => this.props.history.push(navItem.url)}
    />
  );

  renderNav = () => {
    if (window.location.href.match(/\/admin/)) {
      return menuItemsForAdmin.map(navItem => this.renderNavItem(navItem));
    }
    return menuItemsForProvider.map(navItem => this.renderNavItem(navItem));
  };

  render() {
    return (
      <div className="burger-menu">
        <IconMenu
          iconButtonElement={
            <IconButton>
              <MenuIcon />
            </IconButton>
          }
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {this.renderNav()}
          <MenuItem primaryText="Logout" onClick={() => this.toggleModal()} />
        </IconMenu>
        <SignOut {...this.props} open={this.state.logoutOpen} toggleModal={this.toggleModal} />
      </div>
    );
  }
}

export default withRouter(BurgerMenu);
