import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ children, style, title }) => (
  <div
    className="db-panel-wrapper"
    style={{
      position: 'relative',
      marginBottom: '20px',
      backgroundColor: 'white',
      ...style,
    }}
  >
    <span className="global-panel-title">{title}</span>
    <div>{children}</div>
  </div>
);

Panel.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
};

Panel.defaultProps = {
  style: {},
  children: null || [],
  title: '',
};

export default Panel;
