/* eslint-disable react/jsx-no-literals */
import React from 'react';
import PropTypes from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LanguageContext } from '../../LanguageProvider';

function LocaleSelectMini({ flags }) {
  if (flags.practiceFrenchLocalization) {
    return (
      <LanguageContext.Consumer>
        {({ locale, setLocale }) => (
          <div className="language-selector-mini">
            <div
              role="button"
              className={locale === 'en' ? 'active' : ''}
              onClick={() => setLocale('en')}
              onKeyDown={() => setLocale('en')}
              tabIndex={0}
            >
              EN
            </div>
            |
            <div
              role="button"
              className={locale === 'fr' ? 'active' : ''}
              onClick={() => setLocale('fr')}
              onKeyDown={() => setLocale('fr')}
              tabIndex={-1}
            >
              FR
            </div>
          </div>
        )}
      </LanguageContext.Consumer>
    );
  }
  return <div></div>;
}

LocaleSelectMini.propTypes = {
  flags: PropTypes.object,
};

export default withLDConsumer()(LocaleSelectMini);
