import React from 'react';
import PropTypes from 'prop-types';

const Card = props => (
  <div style={props.style} className={`global-card ${props.class}`}>
    {props.children}
  </div>
);

Card.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  class: PropTypes.string,
};

Card.defaultProps = {
  style: {},
  children: null || [],
  class: '',
};

export default Card;
